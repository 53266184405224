import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
export * from 'react-router-dom';

/**
 * Explicitly list exports of "history" because of conflicting re-exports.
 *
 * "src/react-router.js" re-exports "createPath" from
 *     both "node_modules/react-router-dom/dist/index.js"
 *     and "node_modules/history/index.js" (will be ignored).
 * "src/react-router.js" re-exports "parsePath" from
 *     both "node_modules/react-router-dom/dist/index.js"
 *     and "node_modules/history/index.js" (will be ignored).
 */
export {
  Action,
  createBrowserHistory,
  createHashHistory,
  createMemoryHistory,
} from 'history';

export function Link({ to, replace, component, ...rest }) {
  const navigate = useNavigate();

  if (window.WF_API_ENDPOINT_HOSTNAME) {
    return React.createElement('a', {
      href: `${window.WF_API_ENDPOINT_HOSTNAME}${to}`,
      ...rest,
      onClick: (e) => {
        e.preventDefault();

        if (window.WF_HANDLE_LINK_CLICKS) {
          window.WF_HANDLE_LINK_CLICKS(to);
        } else {
          navigate(to);
        }
      },
    });
  }

  return React.createElement(RouterLink, { to, replace, component, ...rest });
}
